import {StaticImage} from "gatsby-plugin-image";
import React from 'react';
import BlogFeed from '../components/blog-feed';
import CTA from '../components/cta';
import Layout from '../components/layout';
import Seo from "../components/seo"
import Quote from "../components/quote";
import patternHex from '../images/backrounds/pattern-hex-white.jpg';
import NeedAQuote from "../components/need-a-quote";


const Index = ({location}) => {

  return (
    <Layout>
      <Seo
        pathName={location.pathname}
        title="Zendesk CoSelling"
        description="Let us help you co-sell Zendesk licenses with our expert development team providing assurance to potential customers."
      />
      <section>
        <div
          className="hero"
          style={{
            backgroundImage: `url(${patternHex})`
          }}
        >
          <div className="hero-title bg-dark-transparent">
            <div className="hero-image">
              <StaticImage
                src="../images/clients/zendesk.png"
                alt="Zendesk Implementation Premier Partner"
                layout="constrained"
                style={{ maxWidth: "200px"}}
              />
            </div>
            <h1 className="text-white">
              zendesk co-selling with 729 solutions
            </h1>
            <p className="text-white">729 Solutions is a Premier Certified Development, Design, and Systems Integration
              Partner. Our Zendesk Services Teams knowledge and expertise can help Zendesk AE’s close a deal.</p>
            <CTA href="/contact-us/" text="Learn How" className="btn btn-primary"/>
          </div>
        </div>
      </section>
      <section>
        <div className="container py-6">
          <div className="row mb-4">
            <div className="col-lg-6">
              <h2>what we know</h2>
              <p>Working with Zendesk Account Executives and Customers in the past few years, we’ve learned a few things
                about why customers hesitate to pull the trigger and purchase Zendesk as their Help Center solution.</p>
              <ul className="text-primary m-0 pl-3">
                <li><span
                  className="text-dark">They’re afraid of the time and resources needed for implementations.</span></li>
                <li><span
                  className="text-dark">They want to be sure Zendesk to integrates with their other tools.</span></li>
                <li><span className="text-dark">Larger customers expect white glove treatment and services to ensure success.</span>
                </li>
                <li><span className="text-dark">Everyone is concerned about ongoing resources to manage Zendesk.</span>
                </li>
                <li><span className="text-dark">Their technical teams are busy with other things, which makes it harder to close deals quickly.</span>
                </li>
              </ul>
            </div>
            <div className="col-lg-6">
              <h2>lean on our experience</h2>
              <p>We can help you close deals by easing customer’s minds about resources, technical capabilities and
                integrations. Let us focus on those details so you can focus on the part you do best.</p>
              <ul className="text-primary m-0 pl-3">
                <li><span className="text-dark">We are happy to get on sales calls to talk about services and resourcing with prospects.</span>
                </li>
                <li><span className="text-dark">We can scope complex development needs and provide solutions architectures.</span>
                </li>
                <li><span
                  className="text-dark">We support proof of concept and other technical evaluations of Zendesk.</span>
                </li>
                <li><span className="text-dark">We will keep you informed of your prospect’s issues, fears and new stakeholders.</span>
                </li>
                <li><span className="text-dark">We will sell the right value proposition for each customer.</span></li>
              </ul>
            </div>
          </div>
          <div className="row pt-5">
            <div className="col">
              <p className="text-center">
                <CTA className="btn btn-primary" href="/contact-us/" text="get zendesk help now"/>
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-dark">
        <div className="container py-6">
          <div className="row">
            <div className="col-12 col-md-7 py-5 text-white">
              <h2 className="text-white mb-2">We’re confident we can help you sell, <strong>because we’ve done it
                before.</strong></h2>
              <p>Read about how we’ve helped sell some large deals with your team.&nbsp;</p>
            </div>
            <div className="col-12 col-md-5 d-flex">
              <div className="d-flex flex-column justify-content-center">
                <a
                  href="https://info.729solutions.com/co-selling-zendesk-case-studies"
                  className="btn btn-secondary-light mb-4">
                  View Case Studies
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container py-6">
          <div className="row">
            <div className="col-lg-7">
              <h2>the most common reasons you need a services partner</h2>
              <p>There are many situations you could find yourself in where having a Services Partner would be
                beneficial for you and your customer. Here are just a few of the reasons we’ve found.</p>
              <ul className="text-primary m-0 pl-3">
                <li><span className="text-dark"><b>Zendesk services won’t be able to handle the request. </b>The implementation, and customizations
                  are outside of the scope of work that Zendesk will accommodate.
                </span></li>
                <li><span className="text-dark"><b>The prospect is price sensitive.</b> We are cheaper.</span></li>
                <li><span className="text-dark"><b>You need to start quickly. </b>We have an entire team ready to roll and can start the project
                  right away.
                </span></li>
                <li><span className="text-dark"><b>There are “off-platform” requirements.</b> Integrations to other software or databases are one of
                  our many specialties; Leave it to the experts!
                </span></li>
                <li><span className="text-dark"><b>Extensions to Zendesk’s base functionality are required. </b>We love creating awesome things, can
                  do it quickly, and are so familiar with the product that we’ll do it efficiently.
                </span></li>
                <li><span className="text-dark"><b>There is a need for managed services or ongoing support.</b> We look out for the client’s best
                  interest and are in it to support them from start to finish and beyond.
                </span></li>
                <li><span className="text-dark"><b>Legacy technology is involved.</b> Think migrations from outdated platforms or closed systems
                  with no open APIs. We will create the tools needed to get the data in the right places, fast.
                </span></li>
              </ul>
            </div>
            <div className="col-lg-5 bg-light p-4">
              <h2>why pick 729 solutions?</h2>
              <ul className="text-primary m-0 pl-3">
                <li><span className="text-dark">We’ve got 15 years of experience as a full stack development shop and systems integrator.</span>
                </li>
                <li><span className="text-dark">We’ve delivered 40+ successful projects with Zendesk.</span></li>
                <li><span className="text-dark">We handle more complex jobs than any other Zendesk Partner.</span></li>
                <li><span className="text-dark">We were the first Premier Consulting Partner.</span></li>
                <li><span
                  className="text-dark">We are a subcontractor to Zendesk (you can refer us or subcontract us)</span>
                </li>
                <li><span className="text-dark">We are committed to helping you sell!</span></li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-dark">
        <div className="container py-6">
          <div className="row">
            <div className="col">
              <h2 className="text-center text-white">Testimonials</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <Quote
                dark
                name="Ches Spencer"
                company="Zift Solutions"
                quote="I can easily say that your team is the most strategic in your transparency and reporting that I have ever experienced."
              />
            </div>
            <div className="col-lg-4">
              <Quote
                dark
                name="Deeanne Akerson"
                company="Kindred Braverly"
                quote="We spent several months trying to sort out our Zendesk workflow on our own with dozens of calls with Zendesk's internal help team. After one call with 729 Solutions, they understood our unique workflow and what we were trying to do. Every eCommerce business needs them on their team!"
              />
            </div>
            <div className="col-lg-4">
              <Quote
                dark
                name="Noopur Shankar"
                company="Optimal Strategies"
                quote="The 729 Solutions Team made great efforts to become aware of our needs and were fast learners."
              />
            </div>
          </div>

        </div>
      </section>
      <NeedAQuote/>
      <BlogFeed containerClassname="py-6"/>
    </Layout>
  )
};

export default Index;
